<template>
  <div>
    <v-row class="mb-4">
<!--      <v-col cols="12" sm="6" md="4" lg="3">-->
<!--        <v-text-field-->
<!--            :label="$t('employee.search.label')"-->
<!--            :placeholder="$t('employee.search.placeholder')"-->
<!--            v-model="search.search"-->
<!--            solo-->
<!--            clearable-->
<!--            hide-details-->
<!--            @click:clear="searchClear"-->
<!--            @keypress.enter="searchItem"-->
<!--        ></v-text-field>-->
<!--      </v-col>-->
<!--      <v-col-->
<!--          class="d-flex ml-md-n5"-->
<!--          cols="12"-->
<!--          sm="6"-->
<!--          md="4"-->
<!--          lg="4"-->
<!--      >-->
<!--        <v-select-->
<!--            v-model="search.search_select"-->
<!--            :items="search_from"-->
<!--            label="Search from"-->
<!--            :append-icon="'mdi-chevron-down'"-->
<!--            solo-->
<!--            hide-details-->
<!--            :menu-props="{ bottom: true, offsetY: true }"-->
<!--        ></v-select>-->
<!--        <v-btn-->
<!--            style="padding: 24px 0 25px 0;"-->
<!--            elevation="2"-->
<!--            color="primary"-->
<!--            medium-->
<!--            class="ml-1"-->
<!--            @click="searchItem"-->
<!--        >-->
<!--          <v-icon size="32">mdi-magnify</v-icon>-->
<!--        </v-btn>-->
<!--      </v-col>-->
      <v-col cols="12" sm="7">
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label=" $t('employee.search.label')"
            hide-details
            solo
            class="mb-6 elevation"
            height="53"
        />
      </v-col>
      <v-col class="d-flex justify-end" sm="12" md="5" lg="5">
        <v-switch
            class="ml-auto mr-7 mt-3"
            v-model="active_employees"
            :label="$t('employee.active_employees')"
        ></v-switch>
        <v-btn
            :to="{name: path === '' ? 'employees.create' : path + '.employees.create'}"
            style="padding: 24px 0 25px 0;"
            elevation="2"
            color="green"
            dark
            medium
            v-if="mxware.can('employee-create')"
        >
          <v-icon size="32">mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
        :headers="headers"
        :items="getAllEmployees"
        :items-per-page="itemsPerPage"
        @page-count="pageCount = $event"
        :search="search"
        :page.sync="page"
        hide-default-footer
    >
      <template v-slot:item.image="{ item }">
        <img width="40" :src="getAvatar(item.avatar)">
      </template>

      <template v-slot:item.gender="{ item }">
        {{ $t(`employee.gender.${item.gender}`) }}
      </template>

      <template v-slot:item.birthday="{ item }">
        {{ getBirthday(item.birthday) }}
      </template>

      <template v-slot:item.is_deleted="{ item }">
        {{ $t(`bool.${ item.is_deleted === 0 ? 'no': 'yes' }`) }}
      </template>

      <template v-slot:item.is_blocked="{ item }">
        {{ $t(`bool.${ item.is_blocked === 0 ? 'no': 'yes' }`) }}
      </template>


      <template v-slot:item.action="{ item }">
        <v-btn text elevation="0" small :to="{name: 'employees.edit', params: {id: item.id}}">
          <v-icon size="16" color="blue-grey darken-4">mdi-pencil-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <div class="pt-2 d-flex justify-center">
      <div>
        <v-pagination
            v-model="page"
            :length="pageCount"
        ></v-pagination>
      </div>
<!--      <div class="ml-auto" style="max-width: 60px;">-->
<!--        <v-text-field-->
<!--            :value="itemsPerPage"-->
<!--            label=""-->
<!--            type="number"-->
<!--            min="-1"-->
<!--            max="10"-->
<!--            @input="itemsPerPage = parseInt($event, 10)"-->

<!--        ></v-text-field>-->
<!--      </div>-->
    </div>


<!--    <div :style="`overflow: auto; position: relative; display: inline-block; width: 100%; max-height: ${height}px;`">-->
<!--      <table class="mx-table-full"  :id="table_id">-->
<!--        <thead class="">-->
<!--        <tr class="red">-->
<!--          <th style="min-width: 40px; max-width: 40px;">ID</th>-->
<!--          <th style="min-width: 40px; max-width: 40px;">{{ $t('employee.image') }}</th>-->
<!--&lt;!&ndash;          <th style="min-width: 120px; max-width: 120px;">{{ $t('employee.identification') }}</th>&ndash;&gt;-->
<!--          <th style="min-width: 120px; max-width: 120px; width: 120px;">{{ $t('employee.position') }}</th>-->
<!--          <th style="width: 160px; max-width: 160px; min-width: 160px; left: 0;">{{ $t('employee.first_name')}}</th>-->
<!--          <th style="width: 160px; max-width: 160px; min-width: 160px; left: 160px">{{ $t('employee.last_name') }}</th>-->
<!--          <th style="min-width: 240px;">{{ $t('employee.email') }}</th>-->
<!--          <th style="min-width: 100px; max-width: 100px;">{{ $t('employee.gender.title') }}</th>-->
<!--          <th style="min-width: 100px; max-width: 100px;">{{ $t('employee.birthday') }}</th>-->
<!--          <th style="min-width: 100px; max-width: 100px;">{{ $t('employee.is_deleted') }}</th>-->
<!--          <th style="min-width: 100px; max-width: 100px;">{{ $t('employee.is_blocked') }}</th>-->
<!--          <th></th>-->
<!--        </tr>-->
<!--        </thead>-->
<!--        <tbody class="xm-table-ld-cursor&#45;&#45;default">-->
<!--        <tr @click="getId(item.uid)" v-for="(item, index) in getAllEmployees" :key="index" :class="{'active': item.id === item_id}">-->
<!--          <td>{{ item.id }}</td>-->
<!--          <td>-->
<!--            <img width="40" :src="getAvatar(item.avatar)">-->
<!--          </td>-->
<!--&lt;!&ndash;          <td>{{ item.identification }}</td>&ndash;&gt;-->
<!--          <td><small>{{ item.position }}</small></td>-->
<!--          <td>{{ item.first_name }}</td>-->
<!--          <td>{{ item.last_name }}</td>-->
<!--          <td>{{ item.email }}</td>-->
<!--          <td>{{ $t(`employee.gender.${item.gender}`) }}</td>-->
<!--          <td>{{ getBirthday(item.birthday) }}</td>-->
<!--          <td>{{ $t(`bool.${ item.is_deleted === 0 ? 'no': 'yes' }`) }}</td>-->
<!--          <td>{{ $t(`bool.${ item.is_blocked === 0 ? 'no': 'yes' }`) }}</td>-->
<!--          <td>-->
<!--            <v-btn v-if="mxware.can('employee-update')" text elevation="0" small :to="{name: 'employees.edit', params: {id: item.id}}">-->
<!--              <v-icon size="16" color="blue-grey darken-4">mdi-pencil-outline</v-icon>-->
<!--            </v-btn>-->
<!--          </td>-->
<!--        </tr>-->
<!--        </tbody>-->
<!--      </table>-->
<!--    </div>-->
<!--    <v-pagination-->
<!--        v-if="total_pages > 1"-->
<!--        v-model="page"-->
<!--        class="my-4"-->
<!--        :total-visible="7"-->
<!--        :length="total_pages"-->
<!--    ></v-pagination>-->
  </div>

</template>

<script>
import moment from "moment";
import options from "../../options";
import {employees} from "@/services/employee/employee_service";

export default {
  props: {
    table_id: {
      type: String,
      default: 'ts'
    },
    path: {
      type: String,
      default: ''
    }
  },
  data() {
    return {

      search: '',
      headers:[
        { text: this.$t('employee.id'), value: 'id', width: '30', sortable: false },
        { text: this.$t('employee.image'), value: 'image', width: '70', sortable: false },
        { text: this.$t('employee.gender.title') , value: 'gender', sortable: false },
        { text: this.$t('employee.first_name') , value: 'first_name' },
        { text: this.$t('employee.last_name') , value: 'last_name' },
        { text: this.$t('employee.email') , value: 'email' },
        { text: this.$t('employee.position') , value: 'position' },
        { text: this.$t('employee.birthday') , value: 'birthday', sortable: false },
        { text: this.$t('employee.is_deleted') , value: 'is_deleted', sortable: false },
        { text: this.$t('employee.is_blocked') , value: 'is_blocked', sortable: false },
        { text: '', value: 'action',sortable: false, width: '150px' },
      ],
      // page: 1,
      pageCount: 0,
      itemsPerPage: 10,


      page: 1,
      meta: 20,
      total_pages: 0,
      active_employees: true,
      isMobile: true,
      height: 0,
      items: [],
      item_id: 1,

      // search: {
      //   search: null,
      //   search_select: 'last_name'
      // },
      search_from: [
        {
          text: this.$t('employee.search.select.first_name'),
          value: 'first_name'
        },
        {
          text: this.$t('employee.search.select.last_name'),
          value: 'last_name'
        },
        {
          text: this.$t('employee.search.select.email'),
          value: 'email'
        },
        // {
        //   text: this.$t('employee.search.select.identification'),
        //   value: 'identification'
        // },
      ],
    }
  },
  computed: {
    getAllEmployees() {
      if (this.active_employees) {
        return this.items.filter(employee => employee.is_deleted === 0)
      } else {
        return this.items
      }

    }
  },
  watch: {
    page() {
      if (this.search.search === null) {
        this.getItems()
      } else {
        this.searchItem()
      }
    },
    'search.search'() {
      if (this.search.search === '') {
        this.search.search = null
        this.getItems()
      }
    }
  },
  created() {
    // setTimeout(this.tableHeight, 300)
    this.getItems()
  },
  mounted() {
    // this.tableHeight()
    this.onResize()
    window.addEventListener('resize', this.onResize, {passive: true})
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize)
    }
  },
  methods: {
    getAvatar(avatar) {
      return avatar !== null ?  options.avatar_url + avatar : '/assets/images/avatar.jpg'
    },
    onResize() {
      this.isMobile = window.innerWidth < 600
    },
    tableHeight() {
      this.height = window.innerHeight - (document.getElementById(this.table_id).getBoundingClientRect().top + 100)
    },
    getId(id) {
      this.item_id = id
      this.$emit('getId', id)
    },
    searchItem() {
      if (this.search.search !== null) {
        this.$store.dispatch('employeeSearch', this.search).then(() => {
          this.total_pages = 1
          this.items = this.$store.getters.employees.data
        })
      }
    },
    getItems() {
      this.$store.dispatch('employees', this.page).then(() => {
        this.generateItems()
      })
    },
    generateItems() {
      if (this.path === '') {
        this.items = this.$store.getters.employees.data
        // this.items = this.$store.getters.employees.data
        this.meta = this.$store.getters.employees.meta
        this.page = this.$store.getters.employees.meta.current_page

        this.total_pages = Math.ceil(this.$store.getters.employees.meta.total / 25)
      }

      if (this.path === 'crm') {
        this.items = this.$store.getters.employeesCrm.data
        this.meta = this.$store.getters.employeesCrm.meta
        this.page = this.$store.getters.employeesCrm.meta.current_page

        this.total_pages = Math.ceil(this.$store.getters.employeesCrm.meta.total / 25)
      }
      this.isLoaded()
    },
    isLoaded() {
      this.$emit('isloaded', true)
    },
    searchClear () {
      this.getItems()
    },
    getBirthday(date,format='YYYY-MM-DD') {
      return moment(date, format).format('DD.MM.YYYY')
    }
  }
}
</script>
