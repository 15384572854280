<template>
  <div style="height: calc(100vh - 48px)">
    <mx-preloader :loader-status="preloader"/>
    <v-container fluid>
      <v-row class="mt-3">
        <v-col cols="12">
          <TableFullEmployee @tableCreated="tableCreated" @getId="getId" @isloaded="isloaded"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import TableFullEmployee from "@/components/Tables/TableFullEmployee";

export default {
  components: { TableFullEmployee },
  data() {
    return {
      preloader: true,
      search: {
        search: null,
        search_select: 'identification'
      },
      search_from: [
        {
          text: 'Identification',
          value: 'identification'
        },
        {
          text: 'First name',
          value: 'first_name'
        },
        {
          text: 'Last name',
          value: 'last_name'
        },
        {
          text: 'Birthday',
          value: 'birthday'
        }
      ],
      customers: [],
      meta: {},
      customer_id: null
    }
  },
  created () {
    // this.$store.dispatch('customers').then(() => {
    //   this.customers = this.$store.getters.customers.data
    //   this.meta = this.$store.getters.customers.meta
    //   this.preloader = false
    // })
    // setTimeout(this.mxPreloader, 700)
  },
  methods: {
    isloaded() {
      this.mxPreloader()
      // setTimeout(this.mxPreloader, 100)
    },
    tableCreated(bool) {
      this.preloader = !bool
    },
    getId(id) {
      this.customer_id = id
    },
    mxPreloader() {
      this.preloader = false
    }
  }
}
</script>
