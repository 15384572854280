var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_c('v-text-field',{staticClass:"mb-6 elevation",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('employee.search.label'),"hide-details":"","solo":"","height":"53"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"sm":"12","md":"5","lg":"5"}},[_c('v-switch',{staticClass:"ml-auto mr-7 mt-3",attrs:{"label":_vm.$t('employee.active_employees')},model:{value:(_vm.active_employees),callback:function ($$v) {_vm.active_employees=$$v},expression:"active_employees"}}),(_vm.mxware.can('employee-create'))?_c('v-btn',{staticStyle:{"padding":"24px 0 25px 0"},attrs:{"to":{name: _vm.path === '' ? 'employees.create' : _vm.path + '.employees.create'},"elevation":"2","color":"green","dark":"","medium":""}},[_c('v-icon',{attrs:{"size":"32"}},[_vm._v("mdi-plus")])],1):_vm._e()],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getAllEmployees,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"page":_vm.page,"hide-default-footer":""},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"width":"40","src":_vm.getAvatar(item.avatar)}})]}},{key:"item.gender",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("employee.gender." + (item.gender))))+" ")]}},{key:"item.birthday",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBirthday(item.birthday))+" ")]}},{key:"item.is_deleted",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("bool." + (item.is_deleted === 0 ? 'no': 'yes'))))+" ")]}},{key:"item.is_blocked",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("bool." + (item.is_blocked === 0 ? 'no': 'yes'))))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","elevation":"0","small":"","to":{name: 'employees.edit', params: {id: item.id}}}},[_c('v-icon',{attrs:{"size":"16","color":"blue-grey darken-4"}},[_vm._v("mdi-pencil-outline")])],1)]}}])}),_c('div',{staticClass:"pt-2 d-flex justify-center"},[_c('div',[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }